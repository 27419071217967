import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';

import gatsbyImageType from '../prop-types/image';
import { STYLES } from '../enums';
import Stack from '../components/systems/stack';
import SEO from '../components/layout/seo';

const Contact = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: ${STYLES.breakpoints.padWidth}px) {
    flex-direction: column-reverse;
    margin: 5vh auto;
  }
`;

const ContactDescription = styled.div`
  width: 30vw;
  margin-right: 8vw;
  @media screen and (max-width: ${STYLES.breakpoints.padWidth}px) {
    margin-right: 0;
    width: 90vw;
  }
  p {
    margin-bottom: 3vh;
  }
`;

const ImgContainer = styled.div`
  width: 35vw;
  @media screen and (max-width: ${STYLES.breakpoints.padWidth}px) {
    width: 90vw;
    margin-bottom: 5vh;
  }
`;

const ContactPage = ({ data }) => (
  <>
    <SEO title="Kontakt" />
    <Contact>
      <ContactDescription>
        <Stack centered={false} gap="30px">
          <h1>KONTAKT</h1>
          <p>
            sollten Sie ein Thema haben, das Sie brennend interessiert und Sie
            glauben, das dies auch anderen Menschen helfen könnte, dann können
            Sie mir dies gerne unter{' '}
            <a
              href="mailto:mail@praxis-landgraf.de"
              target="_blank"
              rel="noopener noreferrer"
            >
              mail@praxis-landgraf.de
            </a>{' '}
            schreiben. Ich werde dann versuchen dies aufzuarbeiten und hier in
            Form eines Seminars anzubieten.
          </p>
          <h2>
            <i>Beate Landgraf</i>
          </h2>
        </Stack>
      </ContactDescription>
      <ImgContainer>
        <Img
          fluid={data.file.childImageSharp.fluid}
          alt="Beate lächelt in die Kamera"
        />
      </ImgContainer>
    </Contact>
  </>
);

ContactPage.propTypes = {
  data: gatsbyImageType.isRequired,
};

export default ContactPage;

export const query = graphql`
  query Portrait_Contact {
    file(relativePath: { eq: "Portrait.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
