import PropTypes from 'prop-types';

const gatsbyImageType = PropTypes.shape({
  file: PropTypes.shape({
    childImageSharp: PropTypes.shape({
      fluid: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.shape({})),
        PropTypes.shape({}),
      ]),
    }),
  }).isRequired,
});

export default gatsbyImageType;
